import BagdeStatus from "components/BadgeStatus"
import Hint from "components/Hint"
import Icon from "components/Icon"
import If from "components/If"
import Text from "components/Typography/Text"

import "./LeadCard.styl"

const LeadCard = ({
  statusColor,
  status,
  name,
  phone,
  email,
  userOwner,
  createdAt,
  updatedAt,
  contactType = null,
  onClick,
  ...props
}) => {
  return (
    <div
      role="presentation"
      aria-label={`Lead: ${name}`}
      className="lead-card"
      onClick={onClick}
      {...props}
    >
      <div className="lead-card__flags">
        <BagdeStatus className="lead-card__status" statusColor={statusColor}>
          {status}
        </BagdeStatus>
        <If condition={contactType === "whatsapp"}>
          <div className="lead-card__contact">
            <Icon className="lead-card__contact-icon" name="whatsapp" />
            <Text className="lead-card__contact-text">WhatsApp</Text>
          </div>
        </If>
      </div>
      <Text className="lead-card__name">{name}</Text>
      <div className="lead-card__content">
        <Text className="lead-card__phone" variant="small">
          {phone}
        </Text>
        <Text className="lead-card__email" variant="small">
          {email}
        </Text>
      </div>
      <div className="lead-card__content lead-card__content--dates">
        <If condition={userOwner}>
          <dl className="lead-card__list lead-card__list--user-owner">
            <div className="lead-card__group">
              <dt className="lead-card__label">Usuário</dt>
              <dd className="lead-card__value lead-card__value--user-owner">
                {userOwner}
              </dd>
              <Hint className="lead-card__user-hint" balloon="left">
                <li>{userOwner}</li>
              </Hint>
            </div>
          </dl>
        </If>
        <If condition={createdAt}>
          <dl className="lead-card__list">
            <dt className="lead-card__label">Criado em</dt>
            <dd
              className="lead-card__value"
              aria-label={`Criado em ${createdAt}`}
            >
              {createdAt}
            </dd>
          </dl>
        </If>
        <If condition={updatedAt}>
          <dl className="lead-card__list">
            <dt className="lead-card__label">Último contato</dt>
            <dd
              className="lead-card__value"
              aria-label={`Último contato ${updatedAt}`}
            >
              {updatedAt}
            </dd>
          </dl>
        </If>
      </div>
    </div>
  )
}

export default LeadCard

import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import isClient from "utils/isClient"

const body = isClient() && document.querySelector("body")

const disabled = () => disableBodyScroll(body)
const enable = () => enableBodyScroll(body)

const hasComeToEnd = (target) => {
  const scrollY = target.scrollHeight - target.scrollTop
  const height = target.offsetHeight
  const offset = Math.floor(height - scrollY)

  return offset === 0 || offset === -1
}

export default {
  hasComeToEnd,
  body: {
    disabled,
    enable,
  },
}

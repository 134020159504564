import Button from "components/Button"
import Can from "components/Can"
import PageHeader from "components/PageHeader"
import Pagination from "components/Pagination"
import CardUnassignedLeads from "./components/CardUnassignedLeads"
import Redistribution from "components/Redistribution"

import useUnassignedLeads from "./hooks"

import "./UnassignedLeads.styl"

const UnassignedLeads = ({ pageContext: { business } }) => {
  const { leads, pagination, redistribution, refresh } =
    useUnassignedLeads(business)

  return (
    <Can
      profile={["admin", "master"]}
      see={["it", "it"]}
      otherwise={{ goTo: ROUTES.home.path }}
    >
      <PageHeader title="Clientes Potenciais" subtitle="Sem usuário">
        <Can business={business.business_permission} do="redistribution">
          <Button
            className="unassigned-leads__button"
            icon="arrows-redistribution"
            variant="group"
            onClick={() => redistribution.onRedistribution()}
          >
            Redistribuir
          </Button>
        </Can>
      </PageHeader>
      <CardUnassignedLeads leads={leads} />
      <Pagination
        currentPage={pagination.current_page}
        pages={pagination.total_pages}
        onChange={refresh}
      />
      <Redistribution {...redistribution} />
    </Can>
  )
}

export default UnassignedLeads

import { useState, useEffect, useCallback } from "react"
import _pagination from "utils/pagination"
import { useParameter } from "hooks/useParameters"
import services from "services"

const useUnassignedLeads = ({ business_id }) => {
  const [groupId] = useParameter("group_id")
  const [leads, setLeads] = useState()
  const [pagination, setPagination] = useState({})
  const [redistribution, setRedistribution] = useState({
    active: false,
    businessId: null,
    groupId: null,
  })

  const handleFetch = useCallback(({ kanban }) => {
    const [response] = kanban
    setLeads(response.paginated_result.data)
    setPagination({
      ...response.paginated_result.pagination,
      quantity: 9,
      total_pages: _pagination.getTotalPages(
        response?.paginated_result?.pagination
      ),
    })
  }, [])

  const fetchUnassignedLeads = useCallback(
    (pagination) => {
      services.bases
        .leads({
          business_id,
          without_user: true,
          pagination: { ...pagination, quantity: 9 },
          group_id: groupId,
        })
        .then(handleFetch)
    },
    [business_id, handleFetch, groupId]
  )

  const onRedistribution = () => {
    setRedistribution({
      active: true,
      businessId: business_id,
      groupId,
    })
  }

  const onCloseRedistribution = () => {
    setRedistribution({ active: false, businessId: null, groupId: null })
    fetchUnassignedLeads()
  }

  useEffect(() => {
    fetchUnassignedLeads()
  }, [fetchUnassignedLeads])

  return {
    leads,
    pagination,
    redistribution: {
      ...redistribution,
      onRedistribution,
      onClose: onCloseRedistribution,
    },
    refresh: fetchUnassignedLeads,
  }
}

export default useUnassignedLeads

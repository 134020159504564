export const getPositions = ({ x, y, width, height, horizontal, vertical }) => {
  const windowWidth = window.innerWidth
  const windowHeight = window.innerHeight

  const { left, right, top, bottom } = {
    left: x + width < 0 && "left",
    right: x + width > windowWidth && "right",
    top: y + height > windowHeight && "top",
    bottom: y + height < 0 && "bottom",
  }

  return {
    horizontal: left || right || horizontal,
    vertical: top || bottom || vertical,
  }
}

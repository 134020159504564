import Card from "components/Card"
import EmptyState from "components/EmptyState"
import If from "components/If"
import LeadCard from "components/LeadCard"

import "./CardUnassignedLeads.styl"

const CardUnassignedLeads = ({ leads = [] }) => {
  return (
    <Card title="Novos Leads" className="card-unassigned-leads">
      <If
        condition={leads.length}
        renderIf={
          <ul className="card-unassigned-leads__list">
            {leads.map((lead, index) => (
              <li
                key={"" + lead?.lead_name + index}
                className="card-unassigned-leads__item"
              >
                <LeadCard
                  statusColor={lead.status_color}
                  status={lead.lead_status}
                  name={lead.lead_name}
                  phone={lead.phone_number}
                  email={lead.email}
                  createdAt={lead.format_created_at}
                  updatedAt={lead.format_updated_at}
                />
              </li>
            ))}
          </ul>
        }
        renderElse={<EmptyState className="card-unassigned-leads__empty" />}
      />
    </Card>
  )
}

export default CardUnassignedLeads

import "./Typography.styl"

import classnames from "classnames"

const Subtitle = ({ className, children, weight = "normal" }) => (
  <h2
    className={classnames("subtitle", {
      [className]: className,
      [`text--${weight}`]: weight,
    })}
  >
    {children}
  </h2>
)

export default Subtitle

import PropTypes from "prop-types"
import { Link } from "gatsby"
import Icon from "components/Icon"
import If from "components/If"
import classnames from "classnames"

import "./Button.styl"

const Button = ({
  link = null,
  className,
  variant,
  size = "medium",
  external = false,
  icon,
  children,
  ...props
}) => {
  const classNames = classnames("button", {
    [className]: className,
    [`button--variant-${variant}`]: variant,
    [`button--size-${size}`]: size,
  })

  return (
    <If
      condition={link}
      renderIf={
        <If
          condition={external}
          renderIf={
            <a
              className={`${classNames} -external `}
              rel="noopener noreferrer"
              href={link}
              {...props}
            >
              {children}
            </a>
          }
          renderElse={
            <Link className={classNames} to={link} {...props}>
              {children}
            </Link>
          }
        />
      }
      renderElse={
        <button className={classNames} {...props}>
          <If condition={icon}>
            <Icon className="button__icon" name={icon} />
          </If>
          {children}
        </button>
      }
    />
  )
}

Button.propTypes = {
  /**
   * An optional link to redirect when clicking the button
   */
  link: PropTypes.string,
  /**
   * Additional CSS classes
   */
  className: PropTypes.string,
  /**
   * Which variant of the button is used
   */
  variant: PropTypes.oneOf(["ghost", "group", "link"]),
  /**
   * The size of the button
   */
  size: PropTypes.oneOf(["small", "medium", "big"]),
  /**
   * Wheter the button redirects to an external or internal page
   */
  external: PropTypes.bool,
  /**
   * An optional icon to be shown in the button
   */
  icon: PropTypes.string,
  /**
   * The display content of the button
   */
  children: PropTypes.node,
}

export default Button

import classnames from "classnames"

import "./BagdeStatus.styl"

const BagdeStatus = ({ statusColor, className, children }) => (
  <span className={classnames("badge-status", className)} id={statusColor}>
    {children}
  </span>
)

export default BagdeStatus

import Icon from "components/Icon"
import Text from "components/Typography/Text"

import classnames from "classnames"

import "./EmptyState.styl"

const EmptyState = ({ className, text = "Nada por aqui" }) => (
  <div className={classnames("empty-state", className)}>
    <Icon className="empty-state__icon" name="empty" />
    <Text className="empty-state__text">{text}</Text>
  </div>
)

export default EmptyState

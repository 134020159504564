import Icon from "components/Icon"
import classnames from "classnames"

import "./Modal.styl"

const Modal = ({ className, children, closeModal, ...props }) => {
  return (
    <div className="modal-container" {...props}>
      <div className={classnames("modal-container__content", className)}>
        {children}
        <Icon
          className="modal-container__close"
          name="close"
          title="close"
          onClick={() => closeModal()}
        />
      </div>
    </div>
  )
}

export default Modal

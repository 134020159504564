import "./Typography.styl"

import classnames from "classnames"

const Text = ({ className, children, variant = "normal" }) => (
  <p
    className={classnames("text", {
      [className]: className,
      [`text--${variant}`]: variant,
    })}
  >
    {children}
  </p>
)

export default Text

import "./Hint.styl"
import useHint from "./hooks"

import classnames from "classnames"

const Hint = ({
  className,
  horizontal = "left",
  vertical = "bottom",
  isSideways = false,
  hasAutoWidth = false,
  children,
}) => {
  const { alignment, hint, onHover } = useHint({ vertical, horizontal })

  return (
    <div
      className={classnames("hint", {
        "hint--sideways": isSideways,
      })}
      onMouseEnter={onHover}
    >
      <div
        ref={hint}
        aria-label="Dica"
        className={classnames(
          "hint__content",
          `hint__content--align-${alignment.vertical}`,
          `hint__content--align-${alignment.horizontal}`,
          {
            [className]: className,
            [`hint--auto-width`]: hasAutoWidth,
          }
        )}
      >
        {children}
      </div>
    </div>
  )
}

export default Hint

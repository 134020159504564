import { useRef, useState } from "react"
import { getPositions } from "./utils"

const useHint = ({ vertical, horizontal }) => {
  const [alignment, setAlignment] = useState({
    horizontal,
    vertical,
  })
  const hint = useRef(null)

  const onHover = () => {
    const { x, y, width, height } = hint.current.getBoundingClientRect()

    const positions = getPositions({
      x,
      y,
      width,
      height,
      ...alignment,
    })

    setAlignment(positions)
  }

  return {
    alignment,
    hint,
    onHover,
  }
}

export default useHint
